import axios from "axios"
import JwtService from "./jwtService"
import { handleLogout } from "../../../redux/authentication"
import { store } from "../../../redux/store"
import { createBrowserHistory } from 'history'

export class JwtServiceChild extends JwtService {

    constructor(jwtOverrideConfig) {
        super(jwtOverrideConfig)
        this.history = createBrowserHistory()
    }

    logout(...args) {
        return axios.post(this.jwtConfig.logoutEndpoint, ...args)
    }

    getToken() {
        return JSON.parse(localStorage.getItem(this.jwtConfig.storageTokenKeyName))
    }
    
    setToken(value) {
        localStorage.setItem(this.jwtConfig.storageTokenKeyName, JSON.stringify(value))
    }

    refreshToken() {
        return axios.post(this.jwtConfig.refreshEndpoint, {
          refreshToken: this.getRefreshToken()
        }).catch(() => {
            store.dispatch(handleLogout())
            this.history.push('/login')
        })
    }
    forgot(...args) {
        return axios.post('/forgot-password', ...args)
    }

    http() {
        return axios
    }
}