// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const dashboardSlice = createSlice({
    name: 'statistics',
    initialState: {
        ordersCount: 0,
        paidOrdersCount: 0,
        recruitersCount: 0,
        jobseekersCount: 0,
        revenue: 0
    },
    reducers: {
        setJobseekerCount: (state, action) => {
            state.jobseekersCount = action.payload['jCount']
        },
        setRecruitersCount: (state, action) => {
            state.recruitersCount = action.payload['rCount']
        },
        setOrdersCount: (state, action) => {
            state.ordersCount = action.payload['oCount']
        },
        setPaidOrdersCount: (state, action) => {
            state.paidOrdersCount = action.payload['paidOrders']
        },
        setRevenue: (state, action) => {
            state.revenue = action.payload['revenue']
        },
        handleStatistics: (state, action) => {
            state.jobseekersCount = action.payload['jCount']
            state.recruitersCount = action.payload['rCount']
            state.ordersCount = action.payload['oCount']
            state.paidOrdersCount = action.payload['paidOrders']
            state.revenue = action.payload['revenue']
        }
    }
})

export const { 
        setJobseekerCount, 
        setRecruitersCount, 
        setOrdersCount, 
        setRevenue,
        handleStatistics,
        setPaidOrdersCount
    } = dashboardSlice.actions

export default dashboardSlice.reducer
