// ** Core JWT Import
// import useJwt from '@src/@core/auth/jwt/useJwt'
import environment from '@environment'
import useJwtChild from '../../@core/auth/jwt/useJwtChild'

const apiBaseUrl = environment.api

const jwtConfig = {
        loginEndpoint: `${apiBaseUrl}/login`,
        registerEndpoint: `${apiBaseUrl}/register`,
        refreshEndpoint: `${apiBaseUrl}/refresh-token`,
        logoutEndpoint: `${apiBaseUrl}/logout`
    }
// const { jwt } = useJwt(jwtConfig)
const { jwt } = useJwtChild(jwtConfig)

export default jwt
