// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import dashboard from './statistics'
import settings from './settings'

const rootReducer = {
  auth,
  navbar,
  layout,
  dashboard,
  settings
}

export default rootReducer
