// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        companies_list: "9.99",
        confidential_mail_box: "9.99",
        geo_filter_add_3: "15.00",
        geo_filter_add_6: "23.99",
        industries_add_3: "19.00",
        redistributes: "4.99"
    },
    reducers: {
        setCompaniesList: (state, action) => {
            state.companies_list = action.payload['companies_list']
        },
        setConfidentialMailBox: (state, action) => {
            state.confidential_mail_box = action.payload['confidential_mail_box']
        },
        setGeoFilterAdd3: (state, action) => {
            state.geo_filter_add_3 = action.payload['geo_filter_add_3']
        },
        setGeoFilterAdd6: (state, action) => {
            state.geo_filter_add_6 = action.payload['geo_filter_add_6']
        },
        setIndustriesAdd3: (state, action) => {
            state.industries_add_3 = action.payload['industries_add_3']
        },
        setRedistributes: (state, action) => {
            state.redistributes = action.payload['redistributes']
        },
        handleSettings: (state, action) => {
            state.companies_list = action.payload['companies_list'] ? action.payload['companies_list'] : state.companies_list
            state.confidential_mail_box = action.payload['confidential_mail_box'] ? action.payload['confidential_mail_box'] : state.confidential_mail_box
            state.geo_filter_add_3 = action.payload['geo_filter_add_3'] ? action.payload['geo_filter_add_3'] : state.geo_filter_add_3
            state.geo_filter_add_6 = action.payload['geo_filter_add_6'] ? action.payload['geo_filter_add_6'] : state.geo_filter_add_6
            state.industries_add_3 = action.payload['industries_add_3'] ? action.payload['industries_add_3'] : state.industries_add_3
            state.redistributes = action.payload['redistributes'] ? action.payload['redistributes'] : state.redistributes
        }
    }
})

export const { 
    setCompaniesList,
    setConfidentialMailBox,
    setGeoFilterAdd3,
    setGeoFilterAdd6,
    setIndustriesAdd3,
    setRedistributes,
    handleSettings
    } = settingsSlice.actions

export default settingsSlice.reducer
