/** JWTChild Service Import */
import { JwtServiceChild } from "./jwtServiceChild"

/***Export Child Service as useJwtChild */
export default function useJwtChild (jwtOverrideConfig) {
    const jwt = new JwtServiceChild(jwtOverrideConfig)

    return {
        jwt
    }
}